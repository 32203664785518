import Vue from 'vue'
import Vuex from 'vuex'
import {
  getUserInfo
} from '@/network/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openid: '',
    hasLoadNecessaryData: false,
    config: {},
    userInfo: {},
    templateId: -1,
    isWeixin: false, // 是否在微信环境
    urlInfo: ''
  },
  mutations: {
    /**
     * 设置用户 openid
     */
    SET_OPENID (state, openid) {
      state.openid = openid
    },

    /**
     * 设置用户信息
     */
    SET_USER_INFO (state, userInfo) {
      state.userInfo = userInfo
    },

    /**
     * 设置获取配置信息状态
     */
    SET_CONFIG_STATUS (state, hasLoadNecessaryData) {
      state.hasLoadNecessaryData = hasLoadNecessaryData
    },

    /**
     * 设置获取配置信息
     */
    SET_CONFIG (state, config) {
      state.config = config
    },

    /**
     * 设置模板 ID
     */
    SET_TEMPLATE_ID (state, id) {
      state.templateId = id
    },

    /**
     * 设置是否是微信环境
     */
    SET_IS_WEIXIN (state, isWeixin) {
      state.isWeixin = isWeixin
    },

    /**
     * 设置域名信息
     */
    SET_URLINFO (state, urlInfo) {
      state.urlInfo = urlInfo
    }
  },
  actions: {
    // 获取用户信息
    GetUserInfo ({ commit, state }, openid) {
      return new Promise((resolve, reject) => {
        if (Object.keys(state.userInfo).length > 0) {
          resolve(state.userInfo)
        } else {
          getUserInfo({ openid }).then(res => {
            commit('SET_USER_INFO', res.data.user_info)
            resolve(res.data.user_info)
          }).catch(err => {
            reject(err)
          })
        }
      })
    }
  },
  modules: {
  }
})
