import axios from 'axios'

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 100000
})

// 发起请求前的处理
service.interceptors.request.use(config => {
  // config.headers['Cache-Control'] = 'no-cache'
  return config
}, error => {
  // console.error(' [REQ ERR] : ', error);
  return Promise.reject(error)
})

// 收到响应的处理
service.interceptors.response.use(response => {
  if (response.headers['content-disposition']) {
    return response
  }

  switch (response.data.code) {
    case 200:
      return response.data

    default:
      return Promise.reject(response.data)
  }
}, error => {
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') > -1) {
    return Promise.reject(new Error('请求超时'))
  }
  // console.error(' [RES ERR] : ', error.response);
  const err = error.response.data
  const status = error.response.status
  let errMsg = ''
  if (err.errors) {
    const errArr = []
    for (const k in err.errors) {
      errArr.push(err.errors[k].toString())
    }
    errMsg = errArr.toString()
  } else {
    errMsg = err.message
  }
  switch (status) {
    default:
      if (!errMsg) {
        errMsg = '当前网络环境较差，请刷新重试'
      }
      return Promise.reject(new Error(errMsg))
  }
})

export default service
