<template>
  <div id="app">
    <keep-alive><router-view/></keep-alive>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
// import { Toast } from 'vant'
import { getUrlInfo } from './network/api/index'
export default {
  name: 'Header',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this._getUrlInfo()
    // this.$router.onReady(() => {
    //   if (!this.$route.query.id && !this.$route.query.scheme && !this.$route.query.appid && this.$route.name !== 'home' && this.$route.name !== 'transfer') {
    //     Toast({
    //       type: 'text',
    //       message: '访问路径出错啦',
    //       duration: 1000
    //     })
    //     return
    //   }

    //   if (this.$route.query.id) {
    //     this.SET_TEMPLATE_ID(Number(this.$route.query.id))
    //     this.$getConfig(this.$route.query.id)
    //     this.$getJssdk()
    //   }
    // })
  },
  methods: {
    ...mapMutations([
      'SET_URLINFO'
    ]),

    _getUrlInfo () {
      try {
        const server = window.location.origin.split('//')[1]
        // const server = 'www.zhenyangyu.cn'
        getUrlInfo({ domain: server }).then(res => {
          this.SET_URLINFO(res.data)
          if (res.data.baiduTongjiKey) {
            let hm = document.createElement('script')
            hm.src = `https://hm.baidu.com/hm.js?${res.data.baiduTongjiKey}`
            let s = document.getElementsByTagName('script')[0]
            s.parentNode.insertBefore(hm, s)
          }
        }).catch(() => {
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss">
html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: PingFang SC;
}

#app {
  width: 100%;
  height: 100%;
}

#sig {
  color: transparent;
  position: absolute;
  top: -200px;
  left: 0;
}

.van-toast--text {
  padding: 20px !important;
}
.van-toast__text {
  font-size: 30px !important;
}
</style>
