import service from '@/network/service/service'
// import store from '@/store'
// import Vue from 'vue'

/**
 * 添加公共参数
 */
function postWithCommonParams (url, params) {
  !params && (params = {})
  // !params.openid && (params.openid = store.state.openid)
  // params.gh_id = Vue.prototype.$ghid
  return service.post(url, params)
}

/* --------------------------- base --------------------------- */
/**
 * @name 获取微信jssdk配置数据
 */
export const getJssdk = (params) => postWithCommonParams('/group/jssdk/config', params)

/**
 * @name 获取配置信息
 */
export const getConfig = (id) => postWithCommonParams(`/group/h5/modules/${id}/template`)

/**
 * @name 获用户信息
 */
export const getUserInfo = (params) => postWithCommonParams('/group/user', params)

/* --------------------------- 活码 --------------------------- */
/**
 * @name 获取活码下二级域名码
 */
export const getSecondUrl = (params) => service.get('/privateApi/pangu/promoteCode/getRandPromoteCode', { params })

/**
 * @name 获取客服个微二维码
 */
export const getWxworkQrCode = (params) => service.get('/privateApi/pangu/custservice/getRandCustservice', { params })

/* --------------------------- 打点 --------------------------- */
export const recordEvent = (params) => service.get('/privateApi/pangu/record/add', { params })

/**
 * @param 获取域名信息
 */
export const getUrlInfo = (params) => service.get('/domain/domainCount', { params })
