import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUrlInfo } from '../network/api/index'
// import store from '@/store'
// let whiteList = ['/jump-to-mp/dispatch']

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      next({ path: 'home', replace: true })
    }
  },
  {
    path: '/home',
    name: 'home',
    meta: { title: '' },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/tuwen-detail',
    name: 'tuwen-detail',
    meta: { title: '' },
    component: () => import('../views/tuwen/TuwenDetail.vue')
  },
  {
    path: '/tuwen-my',
    name: 'tuwen-my',
    meta: { title: '' },
    component: () => import('../views/tuwen/TuwenMy.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 对于所有路由导航，让页面滚动到顶部
    return { x: 0, y: 0 }
  }
})

// router.beforeEach((to, from, next) => {
//   if (store.state.openid || whiteList.indexOf(to.path) !== -1) {
//     next()
//   } else {
//     // 获取本地 openid
//     let openid = window.localStorage.openid

//     // 如果本地存在 openid ，判断是否需要重新获取（每天重新获取一次）
//     let info = window.localStorage.openidInfo ? JSON.parse(window.localStorage.openidInfo) : ''

//     if (openid && info && new Date(info.timestamp).toDateString() === new Date().toDateString()) {
//       // 全局保存用户 本地 openid
//       store.commit('SET_OPENID', openid)

//       next()
//     } else {
//       if (to.query.openid) {
//         // 全局保存用户 重定向 openid
//         store.commit('SET_OPENID', to.query.openid)

//         // 本地保存用户 openid
//         window.localStorage.openid = to.query.openid

//         // 本地保存获取时间
//         window.localStorage.openidInfo = JSON.stringify({
//           timestamp: Date.now()
//         })

//         // 参数拼接
//         let params = []
//         for (const key in to.query) {
//           if (key !== 'openid') {
//             let query = `${key}=${to.query[key]}`
//             params.push(query)
//           }
//         }

//         next({ path: params.length ? `${to.path}?${params.join('&')}` : to.path, replace: true })
//       } else {
//         let redirectUrl = Object.keys(to.query).length ? `${window.location.href}&redirect=1` : `${window.location.href}?redirect=1`

//         // 静默授权
//         window.location.replace(`${process.env.VUE_APP_BASE_API}/group/wx/re?timestamp=${new Date().getTime()}&gh_id=${Vue.prototype.$ghid}&url=${encodeURIComponent(redirectUrl)}`)
//       }
//     }
//   }
// })

router.afterEach(async (to, from) => {
  try {
    const server = window.location.origin.split('//')[1]
    // const server = 'www.zhenyangyu.cn'
    getUrlInfo({ domain: server }).then(res => {
      document.title = res.data.siteName
    }).catch(() => {
    })
  } catch (error) {
    console.log(error)
  }
})

export default router
